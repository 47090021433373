import {Component, EventEmitter, Host, Inject, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Tk3dService} from '../../tk3d.service';
import {InteractionMessageProtocol, ModuleSize} from '../../definitions/index';
import {View3dService} from './view3d.service';
import {ALL_VIEW3D_EVENTS} from './view3deventsarray-helper.provider';

@Component({
  selector: 'tk3d-view3d',
  templateUrl: './view3d.component.html',
  styleUrls: ['./view3d.component.css'],
  providers: [View3dService]
})
export class View3dComponent implements OnChanges {
  /**
   * The panoID loaded
   * This will load the pano
   *  @example S_04-5-9
   */
  @Input() panoID: string;
  /**
   * The map module size
   * Specify it its mini or large
   * This will change the container
   */
  @Input() size: ModuleSize;
  /**
   * Displays loader overlay or not
   */
  @Input() showLoader: boolean;
  /**
   * If you want to reset the module to load new changes put this boolean to true
   */
  @Input() reset: boolean;
  /**
   * All events are emmited with this event emitter
   */
  @Output() tk3dgeneral = new EventEmitter<InteractionMessageProtocol>();
  /*@Output() tk3dfullscreen = new EventEmitter<InteractionMessageProtocol>();
  @Output() tk3dloaded = new EventEmitter<InteractionMessageProtocol>();
  @Output() tk3duserevent = new EventEmitter<InteractionMessageProtocol>();
  @Output() tk3dcontextready = new EventEmitter<InteractionMessageProtocol>();
  @Output() tk3dgeneral = new EventEmitter<InteractionMessageProtocol>();*/

  emitters = {};
  loaderHidden: boolean;
  constructor(private tk3d: Tk3dService, @Host() private v3dService: View3dService,
              @Inject(ALL_VIEW3D_EVENTS) private allMapEvents) {
    this.emitters['tk3dgeneral'] = this.tk3dgeneral;
    this.v3dService.init( this.emitters);
    /*const allEvents = allMapEvents.concat('general');
    for (const eventCallback of allEvents) {
      this.emitters[eventCallback] = this['tk3d' + eventCallback];
    }
    console.log(this.emitters);*/
  }

  ngOnChanges(changes) {
    if (changes['panoID'] && changes['panoID'].currentValue) {
      this.v3dService.loadElement(changes['panoID'].currentValue);
    }
    if (changes['size'] && changes['size'].currentValue && !changes['size'].firstChange) {
      this.v3dService.changeContainer(changes['size'].currentValue);
    }
    if (changes['showLoader']) {
      this.loaderHidden = !this.showLoader;
    }
    if (changes['reset'] && changes['reset'].currentValue) {
      this.v3dService.destroy();
      this.v3dService.init( this.emitters);
      if (this.panoID) {
        this.v3dService.loadElement(this.panoID);
      }
    }
  }
}
