import {Inject, Injectable, Injector, Optional} from '@angular/core';
import {Handler} from '../handler';
import {GeneralState, InteractionMessageProtocol} from '../../definitions/index';
import {TK3dResizeHelper} from '../../tk3d-resize-helper.service';
import {AvailabilityService} from '../../definitions/index';
import {TK3D_CONFIG, Tk3dConfig} from '../../configuration/tk3d.config';
import {Tk3dService} from '../../tk3d.service';
import {StaticInjector} from '@angular/core/src/di/injector';

@Injectable()
export class BlockmapLoadHandler extends Handler {
  constructor(private availability: AvailabilityService, @Optional() private aux: TK3dResizeHelper,
              @Inject(TK3D_CONFIG) private config: Tk3dConfig, private injector: Injector) {
    super();
    this.handling['blockmap'] = ['loaded'];
  }

  handle(message: InteractionMessageProtocol, status: GeneralState) {
    if (this.config.hasOwnProperty('resizeWrappers')) {
      this.aux.setMaxHeight(true);
    }
    const removeUnavailable = this.config.removeUnavailable !== undefined && this.config.removeUnavailable['blockmap'] !== undefined ?
      this.config.removeUnavailable['blockmap'] : true;
    this.availability.getGeneralAvailability()
      .subscribe(data => {
        status.blockmap.moduleRef['setAvailability'](data, removeUnavailable);
        const tk3d = this.injector.get(Tk3dService);
        tk3d.onMapAvailabilityLoaded('blockmap');
      });
  }

}
