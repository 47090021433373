import {Injectable} from '@angular/core';
import {Handler} from '../handler';
import {GeneralState, InteractionMessageProtocol} from '../../definitions/index';

@Injectable()
export class BlockSelectedHandler extends Handler {
  constructor() {
    super();
    this.handling['blockmap'] = ['elementselected'];
  }

  handle(message: InteractionMessageProtocol, status: GeneralState) {
    status.blockmap.selected = message.element.id;
    status.blockmap.size = 'mini';
    status.seatmap.visible = true;
    status.seatmap.size = 'large';
    // status.seatmap.moduleRef['show']();
    // status.blockmap.moduleRef['setContainer']('blockmap-mini');
  }
}
