import { InjectionToken } from '@angular/core';

export interface SelectionFlags {
  multiple_selection?: boolean;
  keep_selections?: boolean;
  user_selection?: boolean;
  available_by_default?: boolean;
  max_selection?: number;
}

export interface SelectionFlagsModule {
  blockmap?: SelectionFlags;
  seatmap?: SelectionFlags;
}

export interface RemoveUnavailable {
  blockmap?: boolean;
  seatmap?: boolean;
}

export interface SeatWrapper {
  seats: string;
  extra?: string [];
}

export interface ResizeWrappers {
  mapWrapper: string;
  seatWrapper: SeatWrapper;
}

export type OnlineConfigurationFunction = (configuration: Tk3dConfig, fileData: any) => void;

export interface OnlineConfiguration {
  function: OnlineConfigurationFunction;
  filePath: string;
}

export type Tk3dPlugins =
  'SelectionPlugin'
  | 'GroupsPlugin'
  | 'MapArrowsPlugin'
  | 'DefaultStyleCleanerPlugin'
  | 'RowsPlugin'
  | 'ZoomButtonsPlugin'
  | 'ThumbnailPlugin'
  | 'SelectionAreaPlugin'
  | 'NeighborsPlugin'
  | 'NavigationPlugin';

export interface BasicConfig {
  availabilityServiceClass: any;
}

/**
 * Configuration structure
 *
 * @example
 export const Tk3dConfigurationEspanyol: Tk3dConfig = {
  venue: 'eu-es-00008',
  flags : {
    seatmap: {
      multiple_selection: true, // default
      keep_selections: false, // default
      user_selection: false, // default
      available_by_default: false, // default
      max_selection: 1 // default
    },
    blockmap: {
      multiple_selection: true,
      keep_selections: false,
      user_selection: false,
      available_by_default: false,
      max_selection: 1
    }
  },
  defaultPlugins: ['SelectionPlugin', 'ZoomButtonsPlugin'], // default
  removeUnavailable: {seatmap: true, blockmap: true}, // default
  resizeWrappers: {
    mapWrapper: 'mapWrapper',
    seatWrapper: {seats: 'seatWrapper', extra: ['extra1', 'extra2']}
  },
basicConfig: {availabilityServiceClass: FooService}, // if this is set, a bunch of handlers are automatically added
injectTk3dService: false
};
 */
export interface Tk3dConfig {
  venue: string;
  mapId?: string;
  resetModules?: boolean;
  basicConfig?: BasicConfig;
  flags?: SelectionFlagsModule;
  defaultPlugins?: Tk3dPlugins[];
  neighborsInfo?: any;
  removeUnavailable?: RemoveUnavailable;
  resizeWrappers?: ResizeWrappers;
  injectTk3dService?: boolean;
  onlineConfiguration?: OnlineConfiguration;
  resizeService?: any;
}

export const TK3D_CONFIG = new InjectionToken('TK3D configuration');
