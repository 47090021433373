import {Component} from '@angular/core';

@Component({
  selector: 'tk3d-blockmap-seatmap',
  templateUrl: './blockmap-seatmap.component.html',
  styleUrls: ['./blockmap-seatmap.component.css']
})
export class BlockmapSeatmapComponent {
  constructor() { }
}

