import {Injectable} from '@angular/core';
import {Handler} from '../handler';
import {GeneralState, InteractionMessageProtocol, AvailabilityService} from '../../definitions/index';

@Injectable()
export class SeatmapClickHandler extends Handler {
  constructor() {
    super();
    this.handling['seatmap'] = ['click'];
  }

  handle(message: InteractionMessageProtocol, status: GeneralState) {
    if (message.emmitterSize !== 'large') {return; }
    const seatStatus = status.seatmap.moduleRef['getElementById'](message.element.id)['status'];
    const maximum = status.seatmap.moduleRef['getMaxSelectables']();
    if (seatStatus.indexOf('selected') !== -1) { // if selected we unselect
      status.seatmap.moduleRef['unselect'](message.element.id);
    } else if (  seatStatus.indexOf('available') !== -1) { // if not available do nothing
      if (status.seatmap.moduleRef['getSelected']().length === maximum && maximum === 1) { // if maximum, unselect the first
        status.seatmap.moduleRef['unselect'](status.seatmap.selected[0]);
      }
      status.seatmap.moduleRef['select'](message.element.id); // finally select the element
    }
  }

}
