import { Component, Inject, OnInit } from '@angular/core';
import {Tk3dService} from '../../tk3d.service';
import { TK3D_CONFIG, Tk3dConfig } from '../../configuration';

@Component({
  selector: 'tk3d-view3d-binded',
  templateUrl: './view3d-binded.component.html',
  styleUrls: []
})
export class View3dBindedComponent {

  constructor(private tk3d: Tk3dService, @Inject(TK3D_CONFIG) private config: Tk3dConfig) { }

  get panoSelected() {
    return this.tk3d.tk3dStatus.view3d.selected;
  }

  get size() {
    return this.tk3d.tk3dStatus.view3d.size;
  }

  get hidden() {
    return !this.tk3d.tk3dStatus.view3d.visible;
  }

  get showLoader() {
    return this.tk3d.tk3dStatus.view3d.showLoader;
  }

  get resetModules() {
    return this.config.resetModules;
  }
}
