import {InjectionToken} from '@angular/core';
import {LoadEvent, MouseEvents, SelectionEvents} from '../../definitions/index';

const mouseEvents: MouseEvents[] = [
  'click', 'mousedown', 'mouseenter', 'mouseleave', 'mousemove', 'mouseout', 'mouseover', 'mouseup'
];

const selectionEvents: SelectionEvents[] = ['elementselected' , 'elementunselected'];

const loadedEvents: LoadEvent[] = ['loaded'];

const emptyArray = [];

const mapModuleEvents: string[] = emptyArray.concat(mouseEvents, selectionEvents, loadedEvents);

// const allMapModuleEvents = mapModuleEvents.concat('general');

export const ALL_MAP_EVENTS = new InjectionToken('mouse events helper');

export const AllMapEventsArrayHelperProvider = {provide: ALL_MAP_EVENTS, useValue: mapModuleEvents};

// TODO use enums


