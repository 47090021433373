import {Inject, Injectable, Injector} from '@angular/core';
import {Handler} from '../handler';
import {GeneralState, InteractionMessageProtocol, AvailabilityService} from '../../definitions/index';
import {TK3D_CONFIG, Tk3dConfig} from '../../configuration/tk3d.config';
import {Tk3dService} from '../../tk3d.service';

@Injectable()
export class SeatmapLoadHandler extends Handler {
  constructor(private availability: AvailabilityService, @Inject(TK3D_CONFIG) private config: Tk3dConfig, private injector: Injector) {
    super();
    this.handling['seatmap'] = ['loaded'];
  }

  private getSize(mapcontainer_id) {
    const bg = document.querySelectorAll('#seatmap .svg-img-background') ;
    let ar = 0;
    if (bg.length > 0) {
      ar = bg[0]['height']['baseVal']['value'] / bg[0]['width']['baseVal']['value'];
    } else {
      ar = 9 / 16;
    }
    return ar;
  }

  private setSize(status: GeneralState) {
    const ar = this.getSize('seatmap'); // Poner aspecto adecuado
    status.seatmap.moduleRef['setAspectRatio'](ar);
  }

  handle(message: InteractionMessageProtocol, status: GeneralState) {
    const removeUnavailable = this.config.removeUnavailable !== undefined && this.config.removeUnavailable['seatmap'] !== undefined ?
      this.config.removeUnavailable['seatmap'] : true;
    this.setSize(status);
    this.availability.getAreaAvailability(status.blockmap.selected as string)
      .subscribe(data => {
        status.seatmap.moduleRef['setAvailability'](data, removeUnavailable);
        const tk3d = this.injector.get(Tk3dService);
        tk3d.onMapAvailabilityLoaded('seatmap');
      });
    // status.blockmap.moduleRef['setContainer']('blockmap-mini');
    // status.blockmap.moduleRef['setContainer']('blockmap-mini');
  }

}
