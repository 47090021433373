import {Injectable} from '@angular/core';
import {Handler} from '../handler';
import {GeneralState, InteractionMessageProtocol} from '../../definitions/index';

@Injectable()
export class SeatUnselectedHandler extends Handler {
  constructor() {
    super();
    this.handling['seatmap'] = ['elementunselected'];
  }

  handle(message: InteractionMessageProtocol, status: GeneralState) {
    const seat = message.element.id;
    const index = status.seatmap.selected.indexOf(seat);
    if (index !== -1) {
      (<string []>status.seatmap.selected).splice(index, 1);
    }
  }
}
