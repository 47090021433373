import {Injectable} from '@angular/core';
import {Handler} from '../handler';
import {GeneralState, InteractionMessageProtocol} from '../../definitions/index';

@Injectable()
export class BlockmapMiniClickHandler extends Handler {
  constructor() {
    super();
    this.handling['blockmap'] = ['click'];
  }

  handle(message: InteractionMessageProtocol, status: GeneralState) {
    if (message.emmitterSize !== 'mini') { return; }
    status.blockmap.size = 'large';
    status.seatmap.visible = false;
    status.blockmap.selected = undefined;
    // status.view3d.visible = false;
    // status.seatmap.moduleRef['hide']();
    // status.blockmap.moduleRef['setContainer']('blockmap-mini');
  }

}
