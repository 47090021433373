import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { TK3D_CONFIG, Tk3dConfig } from './tk3d.config';

@Injectable()
export class Tk3dFileConfiguratorService {

  constructor(private http: HttpClient, @Inject(TK3D_CONFIG) private config: Tk3dConfig) {}

  public configureFromFile(callback) {
    return this.http.get(this.config.onlineConfiguration.filePath).pipe(
      tap(data => {
        callback(this.config, data);
      })
    ).toPromise();
  }
}
