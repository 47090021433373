import {Component, Input, OnInit} from '@angular/core';
import {InteractionMessageProtocol, MapModuleType} from '../../../definitions';
import {Tk3dService} from '../../../tk3d.service';

@Component({
  selector: 'tk3d-map-mini',
  templateUrl: './map-mini.component.html',
  styleUrls: ['./map-mini.component.css']
})
export class MapMiniComponent implements OnInit {
  @Input() type: MapModuleType;
  // @Output() tk3dclick = new EventEmitter<InteractionMessageProtocol>();
  constructor(private tk3d: Tk3dService) { }

  ngOnInit() {
  }
  onClick() {
    const message: InteractionMessageProtocol = {
      event: 'click',
      emmitterSize: 'mini',
      emitter: this.type,
      // element: elementClicked as TK3dElement
    };
    this.tk3d.handle(message);
  }
}
