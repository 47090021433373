import {GeneralState, InteractionMessageProtocol} from '../definitions';
import {HandlerMessage} from './handler-message';
import {Handling} from './handling';

export abstract class Handler {
  protected handling:  Handling = {
    blockmap: [],
    seatmap: [],
    view3d: [],
  };

  canHandle(message: HandlerMessage): boolean {
    return this.handling[message.type].indexOf(message.event) !== -1;
  }
  abstract handle(message: InteractionMessageProtocol, status: GeneralState);
}
