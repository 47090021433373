import { Injectable, Inject } from '@angular/core';
import {InteractionMessageProtocol, MouseEvents, MapModuleType, ModuleSize, Tk3dModuleType, TK3dElement} from '../../definitions/';
import { Tk3dService } from '../../tk3d.service';
import { ALL_MAP_EVENTS } from './mapeventsarray-helper.provider';
import {BasicModuleService} from '../basic-module.service';
import {TK3D_CONFIG, Tk3dConfig} from '../../configuration/tk3d.config';

@Injectable()
export class Tk3dMapService extends BasicModuleService {
  private mapModuleType: MapModuleType;
  private blockSelected;
  private defaultPlugins = ['SelectionPlugin', 'ZoomButtonsPlugin'];
  private defaultFlags = {
    multiple_selection: true,
    keep_selections: false,
    user_selection: false,
    available_by_default: false,
    max_selection: 1
  };

  private configurator() {
    if (this.config.flags && this.config.flags[this.mapModuleType]) {
      for ( const flag of Object.keys(this.config.flags[this.mapModuleType])) {
        this.defaultFlags[flag] = this.config.flags[this.mapModuleType][flag];
      }
    }
    if (this.config.defaultPlugins) {
      this.defaultPlugins = this.config.defaultPlugins;
      if ( this.defaultPlugins.indexOf('SelectionPlugin') === -1) {
        this.defaultPlugins.push('SelectionPlugin');
      }
    }
  }

  constructor(tk3d: Tk3dService, @Inject(ALL_MAP_EVENTS) private mouseEventsCallbacks, @Inject(TK3D_CONFIG) private config: Tk3dConfig) {
    super(tk3d);
    for (const mouseEventCallback of this.mouseEventsCallbacks) {
      this.callbacks[mouseEventCallback] = this.constructCallbackBinded(mouseEventCallback);
    }
  }

  init(emmitters, mapType: MapModuleType) {
    this.emitters = emmitters;
    this.mapModuleType = mapType;
    this.configurator();
    this.selector = this.mapModuleType;
    this.miniSelector = this.mapModuleType + '-mini';
    this.size = 'large';
    this.blockSelected = undefined;
    const seatBlock = mapType === 'blockmap' ? 'block' : 'seat';
    const callbacks = {};
    callbacks[seatBlock] = this.callbacks;
    const selection = {};
    selection[seatBlock] = {};
    selection[seatBlock]['flags'] = this.defaultFlags;
    const config = {
      module: 'map',
      container: mapType,
      plugins: this.defaultPlugins,
      config: {
        callbacks: callbacks,
        selection: selection,
      }
    };
    if (this.config.neighborsInfo) {
      config.config['NeighborsPlugin'] = {};
      config.config['NeighborsPlugin']['neighbors'] = this.config.neighborsInfo;
      config.config['callbacks']['neighbors'] = {};
      config.config['callbacks']['neighbors']['clickNeighbor'] = this.constructCallbackBinded('clickNeighbor');
    }

    this.moduleRef = this.tk3d.loadModule(config, this.mapModuleType);
    window[mapType] = this.moduleRef;
  }

  loadElement(blockID) {
    this.blockSelected = blockID;
    this.moduleRef.loadMap(blockID, this.callbacks['loaded']);
  }

  onLoad() {
    // console.log('load');
  }

  /*changeContainer(size: ModuleSize) {
    const selector = size === 'large' ? this.type :  this.miniSelector;
    try {
      this.mapModule.setContainer(selector);
      this.size = size;
    } catch (err) {
      console.log(err);
    }
  }*/

  protected constructMessage(event, elementClicked): InteractionMessageProtocol {
    const message: InteractionMessageProtocol = {
      event: event,
      emitter: this.mapModuleType,
      emmitterSize: this.size,
      element: elementClicked as TK3dElement
    };
    return message;
  }

  toggleVisibility(show: boolean) {
    const action = show ? 'show' : 'hide';
    this.moduleRef[action]();
  }
  /*handleCallback(event, elementClicked) {
    const message = this.constructMessage(event, elementClicked);
    this.emitters[event].emit(message);
    this.emitters['general'].emit(message);
  }*/
}
