import { Component, OnInit } from '@angular/core';
import { Tk3dService } from '../../tk3d.service';
import { InteractionMessageProtocol } from '../../definitions/index';

import {TK3D_HANDLERS} from '../../handlers/index';
import {BlockSelectedHandler} from '../../handlers/basic/block-selected.handler';

@Component({
  selector: 'tk3d-relocation',
  templateUrl: './tk3drelocation.component.html',
  styleUrls: ['./tk3drelocation.component.css'],
  providers: [Tk3dService,
    {provide: TK3D_HANDLERS, multi: true, useClass: BlockSelectedHandler}]
})
export class Tk3drelocationComponent implements OnInit {
  constructor(private tk3d: Tk3dService) { }

  ngOnInit() {
  }
  get blockSelected() {
    return this.tk3d.tk3dStatus.blockmap.selected;
  }
  generalHandle(message: InteractionMessageProtocol) {
    this.tk3d.handle(message);
  }
}
