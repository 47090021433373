import {Injectable} from '@angular/core';
import {Handler} from '../handler';
import {GeneralState, InteractionMessageProtocol} from '../../definitions/index';

@Injectable()
export class MapMouseEnterHandler extends Handler {
  private blockPopover: HTMLElement;
  private seatPopover: HTMLElement;

  constructor() {
    super();
    this.handling['blockmap'] = ['mouseenter'];
    this.handling['seatmap'] = ['mouseenter'];
    this.blockPopover =
      this.seatPopover = document.getElementById('blockPopover');

  }

  handle(message: InteractionMessageProtocol, status: GeneralState) {
    if (message.emmitterSize === 'mini') {
      return;
    }
    const rect = message.element.HTMLElement.getBoundingClientRect();
    let elementToDisplay;
    const sector = message.element.id.split('_')[1].split('-')[0];
    if (message.emitter === 'blockmap') {
      elementToDisplay = document.getElementById('blockPopover');
      const blockText = document.getElementById('blockPopoverText');
      blockText.innerText = sector ;
    } else {
      elementToDisplay = document.getElementById('seatPopover');
      const row = message.element.id.split('_')[1].split('-')[1];
      const seat = message.element.id.split('_')[1].split('-')[2];
      document.getElementById('seatPopoverRow').innerText = row;
      document.getElementById('seatPopoverSeat').innerText = seat;
      document.getElementById('seatPopoverSector').innerText = sector;
    }
    elementToDisplay.style.left = `${(rect.left + 40)}px`;
    elementToDisplay.style.top = `${rect.top - 20}px`;
    elementToDisplay.style.display = 'block';
  }

}
