import {Injectable} from '@angular/core';
import {Handler} from '../handler';
import {GeneralState, InteractionMessageProtocol} from '../../definitions/index';

@Injectable()
export class SeatSelectedHandler extends Handler {
  constructor() {
    super();
    this.handling['seatmap'] = ['elementselected'];
  }

  handle(message: InteractionMessageProtocol, status: GeneralState) {
    const maximum = status.seatmap.moduleRef['getMaxSelectables']();
    const selected: string[] = status.seatmap.selected as string [];
    selected.push(message.element.id);
    /*if (selected.length > maximum ) {
      selected.shift();
    }*/
    // status.seatmap.moduleRef['show']();
    // status.blockmap.moduleRef['setContainer']('blockmap-mini');
  }
}
