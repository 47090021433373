import {Inject, Injectable} from '@angular/core';
import {TK3D_CONFIG, Tk3dConfig} from './configuration/tk3d.config';

@Injectable()
export class TK3dResizeHelper {

  private oldw;
  private oldh;
  private mapWrapper;
  private seatWrapper;
  private extra;
  constructor(@Inject(TK3D_CONFIG) private config: Tk3dConfig) {
    if (!this.config.resizeWrappers) {
      console.error('Service should be initialized with the wrappers ids');
    }
    this.mapWrapper = this.config.resizeWrappers.mapWrapper;
    this.seatWrapper = this.config.resizeWrappers.seatWrapper.seats;
    this.extra = this.config.resizeWrappers.seatWrapper.extra || [];
  }

  setMaxHeight(force =  false ): void {
    const neww = window.innerWidth;
    const newh = window.innerHeight;
    if (this.oldw !== neww || this.oldh !== newh || force === true) {
      this.oldw = neww;
      this.oldh = newh;
      let h = document.getElementById(this.mapWrapper).offsetHeight;
      for (const extra of this.extra) {
        h -= document.getElementById(extra).offsetHeight;
      }
      document.getElementById(this.seatWrapper).style.height = `${h}px`;
    }
    requestAnimationFrame(this.setMaxHeight.bind(this));
  }



}
