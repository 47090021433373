import {Injectable} from '@angular/core';
import {Handler} from '../handler';
import {GeneralState, InteractionMessageProtocol} from '../../definitions/index';

@Injectable()
export class MapMouseLeaveHandler extends Handler {

  constructor() {
    super();
    this.handling['blockmap'] = ['mouseleave'];
    this.handling['seatmap'] = ['mouseleave'];
  }

  handle(message: InteractionMessageProtocol, status: GeneralState) {
    if (message.emmitterSize === 'mini') {
      return;
    }
    const elementToHide = message.emitter === 'blockmap' ?
      document.getElementById('blockPopover') :  document.getElementById('seatPopover');
    elementToHide.style.display = 'none';
  }
}
