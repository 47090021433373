import {Injectable} from '@angular/core';
import {Handler} from '../handler';
import {GeneralState, InteractionMessageProtocol} from '../../definitions/index';

@Injectable()
export class BlockClickHandler extends Handler {
  constructor() {
    super();
    this.handling['blockmap'] = ['click'];
  }

  handle(message: InteractionMessageProtocol, status: GeneralState) {
    if (message.emmitterSize !== 'large' || status.blockmap.size === 'mini') { return; }
    status.blockmap.moduleRef['unselectAll']();
    status.blockmap.moduleRef['select'](message.element.id);
    // status.blockmap.moduleRef['setContainer']('blockmap-mini');
    // status.blockmap.moduleRef['setContainer']('blockmap-mini');
  }

}
