import { Component, Inject, OnInit } from '@angular/core';
import { Tk3dService } from '../../tk3d.service';
import { TK3D_CONFIG, Tk3dConfig } from '../../configuration';

@Component({
  selector: 'tk3d-blockmap-binded',
  templateUrl: './blockmap-binded.component.html',
  styleUrls: []
})
export class BlockmapBindedComponent {

  constructor(private tk3d: Tk3dService, @Inject(TK3D_CONFIG) private config: Tk3dConfig) {
  }

  get blockmapSize() {
    return this.tk3d.tk3dStatus.blockmap.size;
  }

  get mapId() {
    return this.config.mapId ? this.config.mapId : 'blockmap';
  }

  get blockmapVisibility() {
    return this.tk3d.tk3dStatus.blockmap.visible;
  }

  get showLoader() {
    return this.tk3d.tk3dStatus.blockmap.showLoader;
  }

  get resetModules() {
    return this.config.resetModules;
  }
}
