import { Component, Host, Inject, Input, OnChanges, Output, EventEmitter } from '@angular/core';

import { MapModuleType, ModuleSize, InteractionMessageProtocol } from '../../definitions/index';
import { Tk3dService } from '../../tk3d.service';
import { Tk3dMapService } from './map.service';
import { ALL_MAP_EVENTS } from './mapeventsarray-helper.provider';

/**
 * Basic map component
 */
@Component({
  selector: 'tk3d-map-module',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  providers: [Tk3dMapService]
})
export class MapComponent implements OnChanges {
  // private moduleRef: {};
  /**
   * The map module type
   * Specify if its blockmap or seatmap
   * This will load the configuration
   */
  @Input() type: MapModuleType;
  /**
   * The map module size
   * Specify it its mini or lare
   * This will change the map container
   */
  @Input() size: ModuleSize;
  /**
   * The mapId loaded
   * This will load the element
   *  @example blockmap
   */
  @Input() mapId: string;
  @Input() plugins: string[];
  /**
   * The map visibility
   * This will show or hide the element
   */
  @Input() visible: boolean;
  /**
   * Displays loader overlay or not
   */
  @Input() showLoader: boolean;
  /**
   * If you want to reset the module to load new changes put this boolean to true
   */
  @Input() reset: boolean;
  @Input() selection_flags: {};
  @Input() extraPluginConfig: {};
  /**
   * All events are emmited with this event emitter
   */
  @Output() tk3dgeneral = new EventEmitter<InteractionMessageProtocol>();

  /*@Output() tk3dclick = new EventEmitter<InteractionMessageProtocol>();
  @Output() tk3dloaded = new EventEmitter<InteractionMessageProtocol>();
  @Output() tk3dmousedown = new EventEmitter<InteractionMessageProtocol>();
  @Output() tk3dmouseenter = new EventEmitter<InteractionMessageProtocol>();
  @Output() tk3dmouseleave = new EventEmitter<InteractionMessageProtocol>();
  @Output() tk3dmousemove = new EventEmitter<InteractionMessageProtocol>();
  @Output() tk3dmouseout = new EventEmitter<InteractionMessageProtocol>();
  @Output() tk3dmouseover = new EventEmitter<InteractionMessageProtocol>();
  @Output() tk3dmouseup = new EventEmitter<InteractionMessageProtocol>();
  @Output() tk3dgeneral = new EventEmitter<InteractionMessageProtocol>();
  @Output() tk3delementselected = new EventEmitter<InteractionMessageProtocol>();
  @Output() tk3delementunselected = new EventEmitter<InteractionMessageProtocol>();*/

  emitters = {};
  loaderHidden: boolean;

  constructor(private tk3d: Tk3dService, @Host() private mapService: Tk3dMapService,
              @Inject(ALL_MAP_EVENTS) private allMapEvents) {
    this.emitters['tk3dgeneral'] = this.tk3dgeneral;
    /*const allEvents = allMapEvents.concat('general');
    for (const eventCallback of allEvents) {
      this.emitters[eventCallback] = this['tk3d' + eventCallback];
    }
    console.log(this.emitters);*/
  }

  ngOnChanges(changes) {
    if (changes['type']) {
      this.mapService.init(this.emitters, this.type);
    }
    if (changes['mapId'] && changes['mapId'].currentValue) {
      this.mapService.loadElement(changes['mapId'].currentValue);
    }
    if (changes['size'] && changes['size'].currentValue && !changes['size'].firstChange) {
      this.mapService.changeContainer(changes['size'].currentValue);
    }
    if (changes['visible'] && !changes['visible'].firstChange) {
      this.mapService.toggleVisibility(changes['visible'].currentValue);
    }
    if (changes['reset'] && changes['reset'].currentValue) {
      this.mapService.destroy();
      this.mapService.init(this.emitters, this.type);
      if (this.mapId) {
        this.mapService.loadElement(this.mapId);
      }
    }
    if (changes['showLoader']) {
      this.loaderHidden = !this.showLoader;
    }
  }
}
