import {Injectable} from '@angular/core';
import {Handler} from '../handler';
import {GeneralState, InteractionMessageProtocol} from '../../definitions/index';

@Injectable()
export class V3dSeatSelectedHandler extends Handler {
  constructor() {
    super();
    this.handling['seatmap'] = ['elementselected'];
  }

  handle(message: InteractionMessageProtocol, status: GeneralState) {
    status.view3d.visible = true;
    status.view3d.selected = message.element.id;
    // status.blockmap.moduleRef['setContainer']('blockmap-mini');
  }
}
