declare var TICKETING3D: any;
import { GeneralState, InteractionMessageProtocol, ResizeService, Tk3dModuleType } from './definitions/index';
import { Inject, Injectable, Optional } from '@angular/core';
import { TK3D_HANDLERS } from './handlers/handler-token';
import { Handler } from './handlers/handler';
import { TK3D_CONFIG, Tk3dConfig } from './configuration/tk3d.config';

/*little wrapper around tk3d*/

@Injectable()
export class Tk3dService {
  private tk3d;
  private status: GeneralState;
  private oldw: number;
  private oldh: number;

  constructor(@Inject(TK3D_HANDLERS) private handlers: Handler[], @Inject(TK3D_CONFIG) private config: Tk3dConfig,
              @Optional() private resizeService: ResizeService) {
    this.tk3d = new TICKETING3D(this.config.venue);
    this.reset();
  }

  get tk3dStatus() {
    return this.status;
  }

  private initialStatus: GeneralState = {
    blockmap: {size: 'large', moduleRef: undefined, selected: undefined, visible: true, showLoader: false},
    seatmap: {size: 'large', moduleRef: undefined, selected: [], visible: false, showLoader: false},
    view3d: {size: 'large', moduleRef: undefined, selected: undefined, visible: false, showLoader: false},
  };

  reset(alsoModules = false) {
    if (typeof this.status === 'undefined') {
      this.status = JSON.parse(JSON.stringify(this.initialStatus));
    } else {
      // tslint:disable-next-line:forin
      for (const module in this.status) {
        for (const key in this.status[module]) {
          if (!alsoModules && key === 'moduleRef') {
            continue;
          }
          this.status[module][key] = this.initialStatus[module][key];
        }
      }
    }
    if (this.resizeService) {
      this.tk3dStatus.resizeForce = false;
      requestAnimationFrame(this.onWindowChange.bind(this));
    }
  }

  action(name: string, ...args) {
    return this.tk3d[name](...args);
  }

  loadModule(config, module: Tk3dModuleType) {
    if (!this.tk3d) {
      alert('initialize tk3d with venue id');
      return;
    }
    return this.status[module].moduleRef = this.tk3d['loadModule'](config);
  }

  onSeatMapMiniClick() {
    const message: InteractionMessageProtocol = {
      emitter: 'seatmap',
      event: 'click',
      emmitterSize: 'mini',
    };
    this.handle(message);
  }

  onBlockMapMiniClick() {
    const message: InteractionMessageProtocol = {
      emitter: 'blockmap',
      event: 'click',
      emmitterSize: 'mini',
    };
    this.handle(message);
  }

  onMapAvailabilityLoaded(type: Tk3dModuleType) {
    const message: InteractionMessageProtocol = {
      emitter: type,
      event: 'availabilityloaded',
      emmitterSize: 'large',
    };
    this.handle(message);
  }

  handle(message: InteractionMessageProtocol) {
    for (const handler of this.handlers) {
      if (handler.canHandle({type: message.emitter, event: message.event})) {
        handler.handle(message, this.status);
      }
    }
  }

  getSectorRowSeat(id: string): { sector: string, row: string, seat: string } {
    let sector, row, seat;
    sector = id.split('_')[1].split('-')[0];
    try {
      row = id.split('_')[1].split('-')[1];
      seat = id.split('_')[1].split('-')[2];
    } catch (e) {
      row = '';
      seat = '';
    }
    return {
      sector: sector,
      row: row,
      seat: seat,
    };
  }

  onWindowChange(): void {
    const neww = window.innerWidth;
    const newh = window.innerHeight;
    if (this.oldw !== neww || this.oldh !== newh || this.tk3dStatus.resizeForce === true) {
      this.oldw = neww;
      this.oldh = newh;
      if (this.tk3dStatus.resizeForce) {
        this.tk3dStatus.resizeForce = false;
      }
      this.resizeService.handleResize(this.tk3dStatus);

    }
    requestAnimationFrame(this.onWindowChange.bind(this));
  }


  changeVenue(venueId, resizeForce = true) {
    this.config.resetModules = false;
    this.config.venue = venueId;
    this.tk3d['changeVenue'](venueId);
    this.reset();
    setTimeout(() => {
      this.config.resetModules = true;
      this.status.resizeForce = resizeForce;
    }, 300);
    setTimeout(() => {
      this.config.resetModules = false;
    }, 600);
  }

}
