import { Component, Inject, OnInit } from '@angular/core';
import {Tk3dService} from '../../tk3d.service';
import { TK3D_CONFIG, Tk3dConfig } from '../../configuration';

@Component({
  selector: 'tk3d-seatmap-binded',
  templateUrl: './seatmap-binded.component.html',
  styleUrls: []
})
export class SeatmapBindedComponent {

  constructor(private tk3d: Tk3dService, @Inject(TK3D_CONFIG) private config: Tk3dConfig) { }

  get blockSelected() {
    return this.tk3d.tk3dStatus.blockmap.selected;
  }

  get seatmapSize() {
    return this.tk3d.tk3dStatus.seatmap.size;
  }

  get seatmapVisibility() {
    return this.tk3d.tk3dStatus.seatmap.visible;
  }

  get showLoader() {
    return this.tk3d.tk3dStatus.seatmap.showLoader;
  }

  get resetModules() {
    return this.config.resetModules;
  }
}
