import { Injectable } from '@angular/core';
import { InteractionMessageProtocol, MapModuleType, Tk3dEvents, TK3dElement, ModuleSize, Tk3dModuleType } from '../definitions';
import { Tk3dService } from '../tk3d.service';

@Injectable()
export abstract class BasicModuleService {

  protected callbacks = {};
  protected emitters = {};
  protected selector: string;
  protected miniSelector: string;
  protected moduleRef: any;
  protected size: ModuleSize;

  protected constructCallbackBinded(event) {
    const boundFunction = (function (...args) { // parenthesis are not necessary
      this.handleCallback(...args);
    }).bind(this, event);
    return boundFunction;
  }

  protected abstract constructMessage(event: Tk3dEvents, extra: TK3dElement | boolean | string | undefined): InteractionMessageProtocol;


  abstract init(emmiters, mapType?);

  abstract loadElement(elementId: string);

  changeContainer(size: ModuleSize) {
    const selector = size === 'large' ? this.selector : this.miniSelector;
    try {
      this.moduleRef.setContainer(selector);
      this.size = size;
    } catch (err) {
      console.log(err);
    }
  }

  constructor(protected tk3d: Tk3dService) {
  }

  handleCallback(event, extra: TK3dElement | boolean | string | undefined) {
    const message = this.constructMessage(event, extra);
    this.tk3d.handle(message);
    this.emitters['tk3dgeneral'].emit(message);
    /*this.emitters[event].emit(message);
    this.emitters['general'].emit(message);*/
  }

  destroy() {
    this.moduleRef['destroy']();
  }

}
