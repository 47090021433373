import { NgModule, ModuleWithProviders, Inject, Provider, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent, AllMapEventsArrayHelperProvider, View3dComponent, AllView3dEventsArrayHelperProvider } from './basic-components';
import { Tk3drelocationComponent } from './composite-components/tk3drelocation';
import { BlockmapSeatmapComponent } from './composite-components/blockmap-seatmap/blockmap-seatmap.component';
import { MapMiniComponent } from './basic-components/map/map-mini/map-mini.component';
import { Tk3dService} from './tk3d.service';
import { TK3dResizeHelper} from './tk3d-resize-helper.service';
import { BlockmapBindedComponent } from './binded-components/map-binded/blockmap-binded.component';
import { View3dBindedComponent } from './binded-components/view3d-binded/view3d-binded.component';
import { SeatmapBindedComponent} from './binded-components/map-binded/seatmap-binded.component';
import {TK3D_CONFIG, Tk3dConfig} from './configuration/tk3d.config';
import {TK3D_HANDLERS} from './handlers/index';
import { AvailabilityService, ResizeService } from './definitions/';
import {
  BlockmapLoadHandler,
  BlockmapMiniClickHandler,
  BlockClickHandler,
  BlockSelectedHandler,
  SeatSelectedHandler, SeatUnselectedHandler,
  SeatmapClickHandler,
  SeatmapLoadHandler,
  MapMouseEnterHandler,
  MapMouseLeaveHandler,
  SeatmapMiniClickHandler
} from './handlers/basic/index';
import { Tk3dFileConfiguratorService } from './configuration/tk3d-file-configurator.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    MapComponent,
    Tk3drelocationComponent,
    BlockmapSeatmapComponent,
    View3dComponent,
    MapMiniComponent,
    BlockmapBindedComponent,
    SeatmapBindedComponent,
    View3dBindedComponent,
  ],
  exports: [
    MapComponent,
    View3dComponent,
    Tk3drelocationComponent,
    BlockmapSeatmapComponent,
    MapMiniComponent,
    BlockmapBindedComponent,
    SeatmapBindedComponent,
    View3dBindedComponent,
  ],
  providers : [
    AllMapEventsArrayHelperProvider,
    AllView3dEventsArrayHelperProvider,
    // Tk3dService,
  ]
})
// TODO correct implementation of forRoot and forChild
export class Tk3dModule {
  static forRoot(config: Tk3dConfig): ModuleWithProviders {
    const providers: Provider [] = [
      // AllMapEventsArrayHelperProvider,
      // AllView3dEventsArrayHelperProvider,
      {provide: TK3D_CONFIG, useValue: config}
    ];
    const basicHandlers: Provider [] = [
      {provide: TK3D_HANDLERS, multi: true, useClass: BlockmapLoadHandler},
      {provide: TK3D_HANDLERS, multi: true, useClass: BlockClickHandler},
      {provide: TK3D_HANDLERS, multi: true, useClass: BlockSelectedHandler},
      {provide: TK3D_HANDLERS, multi: true, useClass: SeatmapLoadHandler},
      {provide: TK3D_HANDLERS, multi: true, useClass: SeatmapClickHandler},
      {provide: TK3D_HANDLERS, multi: true, useClass: SeatSelectedHandler},
      {provide: TK3D_HANDLERS, multi: true, useClass: SeatUnselectedHandler},
      // {provide: TK3D_HANDLERS, multi: true, useClass: MapMouseEnterHandler},
      // {provide: TK3D_HANDLERS, multi: true, useClass: MapMouseLeaveHandler},
      {provide: TK3D_HANDLERS, multi: true, useClass: BlockmapMiniClickHandler},
      {provide: TK3D_HANDLERS, multi: true, useClass: SeatmapMiniClickHandler},
    ];
    let finalProviders: Provider [] = providers;
    if (config.basicConfig) {
      finalProviders = providers.concat(basicHandlers);
      const availabilityProvider = { provide: AvailabilityService, useClass: config.basicConfig.availabilityServiceClass };
      finalProviders.push(availabilityProvider);
    }
    if (config.resizeWrappers) {
      finalProviders.push(TK3dResizeHelper);
    }
    if (config.injectTk3dService) {
      finalProviders.push(Tk3dService);
    }
    if (config.onlineConfiguration) {
      finalProviders.push(Tk3dFileConfiguratorService);
      finalProviders.push({ provide: APP_INITIALIZER, useFactory: (configService: Tk3dFileConfiguratorService) =>
          () => configService.configureFromFile(config.onlineConfiguration.function), deps: [Tk3dFileConfiguratorService], multi: true });
    }
    if (config.resizeService) {
      const resizeProvider = { provide: ResizeService, useClass: config.resizeService };
      finalProviders.push(resizeProvider);
    }
    return {
      ngModule: Tk3dModule,
      providers: finalProviders
    };
  }
  static forChild(config: Tk3dConfig): ModuleWithProviders {
    return Tk3dModule.forRoot(config);
  }
}
